<template>
  <v-col
    cols="12"
  >
    <div
      v-if="!pageLoading"
      class="top-card table-card"
    >
      <div
        is="CustomCard"
        v-if="!error"
        :title="bill.transactionId"
        icon="monetization_on"
      >
        <div class="invoice-info">
          <div class="meeting-info invoice">
            <p>
              <strong>Date</strong>
            </p>
            <span
              v-if="bill.createdAt"
            >
              {{ formatDate.basic(bill.createdAt) }}
            </span>
          </div>
          <div class="meeting-info invoice">
            <p>
              <strong>ID</strong>
            </p>
            <span v-if="bill.transactionId">
              {{ bill.transactionId }}
            </span>
          </div>
          <div class="meeting-info invoice">
            <p>
              <strong>Type</strong>
            </p>
            <span
              v-if="bill.type"
              class="type-receipt"
            >
              {{ bill.type }}
            </span>
          </div>
          <div class="meeting-info invoice">
            <p>
              <strong>Paid</strong>
            </p>
            <v-chip
              v-if="bill.paid && bill.paid === true"
              disabled
              class="elevation-1 success-chip"
              text-color="white"
            >
              <v-icon>mdi-check</v-icon>
            </v-chip>
            <v-chip
              v-if="bill.paid && bill.paid === false"
              disabled
              class="elevation-1 neutral-chip"
              text-color="white"
            >
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-chip>
          </div>
          <div class="meeting-info invoice">
            <p>
              <strong>Price</strong>
            </p>
            <span
              v-if="bill.price !== null && bill.price !== undefined"
            >
              {{
                bill.price }}
              <span
                v-if="bill.currency"
              >
                {{ bill.currency.toUpperCase() }}
              </span>
            </span>
          </div>
          <div class="meeting-info invoice">
            <p>
              <strong>Total tax</strong>
            </p>
            <span
              v-if="bill.vatTotal !== null && bill.vatTotal !== undefined"
            >
              {{ bill.vatTotal }}
            </span>
          </div>
          <div class="meeting-info invoice">
            <p>
              <strong>Total</strong>
            </p>
            <span
              v-if="bill.total !== null && bill.total !== undefined"
            >
              {{
                bill.total }}
              <span
                v-if="bill.currency"
              >
                {{ bill.currency.toUpperCase() }}
              </span>
            </span>
          </div>
          <div class="meeting-info invoice empty">
            <p>
              <strong />
            </p>
          </div>
          <div class="meeting-info invoice">
            <p>
              <strong>Products</strong>
            </p>
          </div>
          <span
            v-for="product in bill.rows"
            :key="product.articleFriendlyName"
            class="product-divs"
          >
            <div class="meeting-info invoice">
              <p>
                <strong>Product name </strong>
              </p>
              <span v-if="product.articleFriendlyName">
                {{ product.articleFriendlyName }}
              </span>
            </div>
            <div class="meeting-info invoice">
              <p>
                <strong>Price</strong>
              </p>
              <span v-if="product.price !== null && product.price !== undefined">
                {{ product.price }}
                <span
                  v-if="product.currency"
                >
                  {{ product.currency.toUpperCase() }}
                </span>
              </span>
            </div>
            <div class="meeting-info invoice">
              <p>
                <strong>Tax rate</strong>
              </p>
              <span v-if="product.tax !== null && product.tax !== undefined">
                {{ product.tax }}
              </span>
            </div>
            <div class="meeting-info invoice empty">
              <p>
                <strong />
              </p>
              <span />
            </div>
          </span>
          <div class="documents">
            <div
              v-if="bill.stripePdfUrl && bill.transactionId"
              class="download-document"
            >
              <v-icon class="doc">
                mdi-receipt
              </v-icon>
              <v-tooltip
                bottom
                color="light-blue darken-4"
              >
                <template #activator="{ on }">
                  <div
                    class="text cursor-pointer"
                    v-on="on"
                    @click="downloadFile(bill.stripePdfUrl)"
                  >
                    <h3
                      style="margin-top: 0.4rem;"
                    >
                      Invoice -{{ bill.transactionId }}.pdf
                    </h3>
                  </div>
                </template>
                <span>Download</span>
              </v-tooltip>
            </div>
            <div
              v-if="bill.s3url && bill.transactionId"
              class="download-document"
            >
              <v-icon class="doc">
                mdi-receipt
              </v-icon>
              <v-tooltip
                bottom
                color="light-blue darken-4"
              >
                <template #activator="{ on }">
                  <div
                    class="text cursor-pointer"
                    v-on="on"
                    @click="downloadFile(bill.s3url)"
                  >
                    <h3
                      style="margin-top: 0.4rem;"
                    >
                      Receipt -{{ bill.transactionId }}.pdf
                    </h3>
                  </div>
                </template>
                <span>Download</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
      <v-card
        v-if="error"
        class="rounded-xl"
      >
        <v-card-text>
          <div class="no-data-container">
            <img
              src="@/assets/img/sadGuy.svg"
              class="login-denied-logo no-data-svg"
              alt="Sad guy"
            >
            <p>Sorry, unable to load bill</p>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-progress-circular
            :size="100"
            :width="5"
            color="accent"
            indeterminate
          />
        </v-row>
      </v-container>
    </div>
  </v-col>
</template>

<script>
import CustomCard from '@/common/CustomCard.vue'
import { formatDate } from '@/config/formatDate.js'
export default {
  name: 'BillComponent',
  components: {
    CustomCard
  },
  props: {
    createAlert: {
      type: Function,
      default: function () {}
    },
    route: {
      type: String,
      default: 'verifications'
    }
  },
  data () {
    return {
      formatDate,
      bill: null,
      billId: '',
      receipts: [],
      receiptsPage: 1,
      receiptsBtn: false,
      tableLoader: false,
      pageLoading: false,
      error: false
    }
  },
  beforeMount () {
    this.getBill(this.getTransactionId())
  },
  methods: {
    getTransactionId () {
      return (this.billId = this.$route.params.id)
    },
    getBill (id) {
      this.pageLoading = true
      this.$http.get(this.route + '/' + id)
        .then(
          res => {
            this.bill = res.data.verification
            this.$root.$emit('selectBill', this.bill)
            this.pageLoading = false
          },
          err => {
            this.error = true
            this.pageLoading = false
            console.error(err)
          }
        )
    },
    downloadFile (data) {
      if (data !== null) {
        window.open(data)
      } else {
        this.createAlert(
          'Could not reach URL at this moment',
          'priority_high',
          'warning'
        )
      }
    }
  }
}
</script>
